import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import dayjs from 'dayjs';

import { TripWizardStateService } from '../../../shared/components/trip-wizard/trip-wizard-state.service';
import { SsrHelperService } from '../../services/ssr-helper.service';

import { ERoutes } from '../../enums/routes.enum';

const DATE_REGEXP_FORMAT_8601 = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

@Injectable({
  providedIn: 'root',
})
export class DynamicResolverService {
  #tripWizardStateService = inject(TripWizardStateService);

  constructor(
    private ssrHelperService: SsrHelperService,
    private r: Router,
  ) {}

  private static isDynamicTripParams(queryParams: any): any {
    const dynamicParams = [
      'cc',
      'cc2',
      'sd',
      'ed',
      'sap',
      'eap',
      'type',
      'sdt',
      'edt',
      'days',
    ];
    let res = false;
    for (const k of Object.keys(queryParams)) {
      if (dynamicParams.indexOf(k) > -1) {
        res = true;
        break;
      }
    }

    return res;
  }

  resolve(route: ActivatedRouteSnapshot): any {
    const currentTrip = this.#tripWizardStateService.currentTripStateValue;

    if (
      !this.ssrHelperService.userAgent ||
      !DynamicResolverService.isDynamicTripParams(route.queryParams)
    )
      return false;

    if (route.queryParams.cc) {
      currentTrip.startingLabel = route.queryParams.cc;
      currentTrip.startingCountry = route.queryParams.cc;
    }

    if (route.queryParams.cc2) {
      currentTrip.endingCountry = route.queryParams.cc2;
      currentTrip.endingCountry = route.queryParams.cc2;
    }

    // overwrite startingCountry
    if (route.queryParams.sap) {
      currentTrip.startingLabel = route.queryParams.sap;
      currentTrip.startingCountry = route.queryParams.sap;
    }

    // overwrite endingCountry
    if (route.queryParams.eap) {
      currentTrip.endingLabel = route.queryParams.eap;
      currentTrip.endingCountry = route.queryParams.eap;
    }

    if (route.queryParams.type) {
      currentTrip.plannerInfo.type = route.queryParams.type;
    }

    if (route.queryParams.sd) {
      currentTrip.startingLabel += `, ${route.queryParams.sd}`;
      currentTrip.startingLocationId = route.queryParams.sd;
    }

    if (route.queryParams.ed) {
      currentTrip.endingLabel += `, ${route.queryParams.ed}`;
      currentTrip.endingLocationId = route.queryParams.ed;
    }

    const isValidStartDate =
      route.queryParams.sdt &&
      DATE_REGEXP_FORMAT_8601.test(route.queryParams.sdt);
    const isValidEndDate =
      route.queryParams.edt &&
      DATE_REGEXP_FORMAT_8601.test(route.queryParams.edt);
    currentTrip.isSpecifyDates = isValidStartDate && isValidEndDate;

    if (currentTrip.isSpecifyDates) {
      currentTrip.tripStartDate = new Date(route.queryParams.sdt);
      currentTrip.tripEndDate = new Date(route.queryParams.edt);
      currentTrip.numDays =
        dayjs(currentTrip.tripEndDate).diff(
          dayjs(currentTrip.tripStartDate),
          'day',
        ) + 1;
    } else {
      currentTrip.numDays = 12;
      currentTrip.tripStartDate = '';
      currentTrip.tripEndDate = '';
    }

    if (route.queryParams.days) {
      currentTrip.numDays = route.queryParams.days;
    }

    this.#tripWizardStateService.currentTripState = currentTrip;

    const supportRedirectUrls = [
      `${ERoutes.SLASH}`,
      `${ERoutes.SLASH}${ERoutes.TRIP_PLANNER}`,
    ];

    if (supportRedirectUrls.includes(location.pathname)) {
      this.r.navigate([ERoutes.SLASH, ERoutes.TRIP_PLANNER]);
    }
    return true;
  }
}
